import MuiCard, { type CardProps as MuiCardProps } from '@mui/material/Card';
import { forwardRef } from 'react';
export interface CardProps extends MuiCardProps {
  'aria-label'?: string;
  'data-test-id'?: string;
  ref?: React.Ref<HTMLDivElement>;
}
export const Card = forwardRef<HTMLDivElement, CardProps>(({
  children,
  ...rest
}, ref) => {
  return <MuiCard ref={ref} {...rest}>
      {children}
    </MuiCard>;
});