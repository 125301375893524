'use client';

import { EdlinkWidgetClient } from '@/components/EdlinkWidget';
import { storage } from '@/features/storage';
import Layout from '@/layout';
import { CenteredLayout } from '@/layout/CenteredLayout';
import LocalizationSection from '@/layout/MainLayout/Header/LocalizationSection';
import { AUTO_JOIN_CODE_KEY } from '@/util/constant';
import { PageLoader } from '@magicschool/loader';
import { Box } from '@magicschool/ui/Box';
import Link from 'next/link';
import { useRouter, useSearchParams } from 'next/navigation';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { LoginButtonClient } from './LoginButtonClient';
import { setToken } from './actions';
export const StudentLoginClient = () => {
  const router = useRouter();
  const searchParams = useSearchParams();
  const joinCode = searchParams.get('joinCode');
  const [loading, setLoading] = useState(!!searchParams.get('token'));
  const [token, setJwtToken] = useState(searchParams.get('token'));
  useEffect(() => {
    if (joinCode) storage.setItem(AUTO_JOIN_CODE_KEY, joinCode);
    if (!token) return;
    const doIt = async () => {
      setLoading(true);

      // Clear the token from the URL
      router.replace(location.pathname);
      await setToken(token);
      setJwtToken(null);
      router.push('/s/rooms');
    };
    doIt();
  }, [token, router, joinCode]);
  if (loading || token) {
    return <PageLoader />;
  }
  return <Layout variant="minimal" data-sentry-element="Layout" data-sentry-component="StudentLoginClient" data-sentry-source-file="StudentLoginClient.tsx">
      {!token && <EdlinkWidgetClient />}
      <CenteredLayout logoVariant="student" data-sentry-element="CenteredLayout" data-sentry-source-file="StudentLoginClient.tsx">
        <LoginButtonClient data-sentry-element="LoginButtonClient" data-sentry-source-file="StudentLoginClient.tsx" />

        <Box marginTop={2} data-sentry-element="Box" data-sentry-source-file="StudentLoginClient.tsx">
          <FormattedMessage id="auth.no-login-message" values={{
          Link: chunks => <Link href={{
            pathname: '/s/join',
            query: joinCode ? {
              joinCode
            } : {}
          }} style={{
            textDecoration: 'underline',
            marginLeft: 1,
            color: 'blue'
          }}>
                  {chunks}
                </Link>
        }} data-sentry-element="FormattedMessage" data-sentry-source-file="StudentLoginClient.tsx" />
          <Box display="flex" justifyContent="center" width="100%" marginTop={2} data-sentry-element="Box" data-sentry-source-file="StudentLoginClient.tsx">
            <LocalizationSection data-sentry-element="LocalizationSection" data-sentry-source-file="StudentLoginClient.tsx" />
          </Box>
        </Box>
      </CenteredLayout>
    </Layout>;
};