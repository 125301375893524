import Link from '@/components/Link';
import Logo, { type LogoType } from '@/components/ui-component/LogoClient';
import type { ReactNode } from 'react';
import { Card } from '@magicschool/ui/Card';
import { Grid } from '@magicschool/ui/Grid';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
const Container = styled('div')(({
  theme
}) => ({
  backgroundColor: theme.palette.grey[100],
  minHeight: '100vh'
}));
export const CenteredLayout = ({
  children,
  logoVariant
}: {
  children: ReactNode;
  logoVariant?: LogoType;
}) => {
  return <Container data-sentry-element="Container" data-sentry-component="CenteredLayout" data-sentry-source-file="index.tsx">
      <Grid container direction="column" justifyContent="flex-end" sx={{
      minHeight: '100vh'
    }} data-sentry-element="Grid" data-sentry-source-file="index.tsx">
        <Grid item xs={12} data-sentry-element="Grid" data-sentry-source-file="index.tsx">
          <Grid container justifyContent="center" alignItems="center" sx={{
          minHeight: 'calc(100vh - 68px)'
        }} data-sentry-element="Grid" data-sentry-source-file="index.tsx">
            <Card sx={{
            maxWidth: {
              xs: 400,
              lg: '80%'
            },
            margin: {
              xs: 2.5,
              md: 3
            },
            p: {
              xs: 2,
              sm: 3,
              xl: 5
            }
          }} data-sentry-element="Card" data-sentry-source-file="index.tsx">
              <Grid container direction="column" spacing={2} alignItems="center" justifyContent="center" data-sentry-element="Grid" data-sentry-source-file="index.tsx">
                <Grid item sx={{
                mb: 3
              }} data-sentry-element="Grid" data-sentry-source-file="index.tsx">
                  <Link href="/" aria-label="theme-logo" data-sentry-element="Link" data-sentry-source-file="index.tsx">
                    <Logo variant={logoVariant} data-sentry-element="Logo" data-sentry-source-file="index.tsx" />
                  </Link>
                </Grid>
                <Grid item data-sentry-element="Grid" data-sentry-source-file="index.tsx">{children}</Grid>
              </Grid>
            </Card>
          </Grid>
        </Grid>
        <Grid item xs={12} sx={{
        m: 3,
        mt: 1
      }} data-sentry-element="Grid" data-sentry-source-file="index.tsx">
          <Typography variant="subtitle2" component={Link} href={window?.location?.origin} target="_blank" rel="noopener noreferrer" underline="hover" data-sentry-element="Typography" data-sentry-source-file="index.tsx">
            MagicSchool.ai
          </Typography>
        </Grid>
      </Grid>
    </Container>;
};