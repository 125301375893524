'use client';

import { Box } from '@magicschool/ui/Box';
import { LoadingButton } from '@magicschool/ui/Buttons/LoadingButton';
import Typography from '@mui/material/Typography';
import { useStore } from 'features/store';
import Image from 'next/image';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
export const LoginButtonClient = () => {
  const [pending, setPending] = useState(false);
  const signinWithProvider = useStore(({
    AuthStoreData: as
  }) => as.signinWithProvider, []);
  return <Box data-sentry-element="Box" data-sentry-component="LoginButtonClient" data-sentry-source-file="LoginButtonClient.tsx">
      <LoadingButton fullWidth hideContentOnLoad variant="outlined" ordinal="secondary" onClick={() => {
      setPending(true);
      signinWithProvider('edlink', '/s/rooms');
    }} disabled={pending} loading={pending} sx={{
      textTransform: 'none'
    }} data-sentry-element="LoadingButton" data-sentry-source-file="LoginButtonClient.tsx">
        <Box display="flex" alignContent="center" alignItems="center" justifyContent="center" gap={1} data-sentry-element="Box" data-sentry-source-file="LoginButtonClient.tsx">
          <Image src="/icons/social-signin-logos/sso.svg" width={25} height={25} alt="Generic SSO Logo" data-sentry-element="Image" data-sentry-source-file="LoginButtonClient.tsx" />
          <Typography variant="subtitle1" color="text.primary" data-sentry-element="Typography" data-sentry-source-file="LoginButtonClient.tsx">
            <FormattedMessage id="auth.sign-in" data-sentry-element="FormattedMessage" data-sentry-source-file="LoginButtonClient.tsx" />
          </Typography>
        </Box>
      </LoadingButton>
    </Box>;
};